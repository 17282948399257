
import template from './sidebar.html';

class SidebarVM {
	constructor() {
		this.items = ko.observableArray([
			{ title: 'Overview', route: '/overview', icon: 'fad fa-house' },
			{ title: 'Vehicles', route: '/vehicles', icon: 'fad fa-truck-moving' },
			{ title: 'Clients', route: '/clients', icon: 'fad fa-person-burst' },
			{ title: 'Trips', route: '/trips', icon: 'fad fa-location-dot' },
			{ type: 'separator' },
			{
				title: 'Processes',
				icon: 'fad fa-microchip',
				items: [
					{
						title: 'Data Import',
						route: '/bgworker/processes/Data Import'
					},	// TODO get from API
					{
						title: 'Tracker Integration',
						route: '/bgworker/processes/Tracker Integration'
					}
				]
			},
			{ title: 'Data Import', icon: 'fad fa-upload', route: '/grape-ui/data_import' },
			{ title: 'Files', icon: 'fad fa-folder-tree', route: '/files' },
			{ title: 'Messages', icon: 'fad fa-envelopes', route: '/messages' },
			{ title: 'SQL Queries', icon: 'fad fa-lasso-sparkles', route: '/sqlquery' },
			{ title: 'Workflows', icon: 'fad fa-chart-network', route: '/workflows' },
			{ title: 'Graphs', icon: 'fad fa-chart-waterfall', route: '/graphs' },
			{ title: 'Forms', icon: 'fad fa-clipboard-list-check', route: '/forms' }
		]);
	}
}

class SidebarPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new SidebarVM(this);
	}
}

export default {
	route: '[/]sidebar',
	page_class: SidebarPage,
	template: template
};
