
import template from './graphs.html';

class GraphsPageVM
{
	constructor()
	{
	}

	submit(formElement)
	{
		console.log('form=',formElement);
	}
}

class GraphsPagePage
{
	constructor(bindings, params, options, element)
	{
		this.viewModel = new GraphsPageVM(this);
	}

}

export default {
	route: '[/]graphs',
	page_class: GraphsPagePage,
	template: template
}
