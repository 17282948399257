import * as GraphData from '../../lib/GraphData.js';
import template from './template.html';

class ClientVM {
	constructor(page) {
		this.page = page;

		this.data = ko.observable(null);
		this.client_id = ko.observable();

		this.client_id.subscribe((id) => {
			this.fetchInfo();
		});

		this.graph_filter_options = ko.observable([
			'today', 'this week', 'this month', 'last month', 'this year', 'all time'
		]);
		this.graph_filter = ko.observable('last month');
		this.graph_filter_data = ko.pureComputed(() => {
			let filter = this.graph_filter();
			let from_value = moment();

			let from = { field: 'period', oper: '>=', value: '' };
			if (filter === 'today')
			{
				from.value = from_value.startOf('day').format('YYYY-MM-DD');
			}
			else if (filter === 'this week')
			{
				from.value = from_value.startOf('week').format('YYYY-MM-DD');
			}
			else if (filter === 'this month')
			{
				from.value = from_value.startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'last month')
			{
				from.value = from_value.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'this year')
			{
				from.value = from_value.startOf('year').format('YYYY-MM-DD');
			}
			else { return []; }

			return [from];
		});
		this.graph_filter_data.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_client_graph_data(this.client_id(), val, this.graph_grouping()));
		});

		this.graph_grouping = ko.observable('weekly');
		this.graph_grouping.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_client_graph_data(this.client_id(), this.graph_filter_data(), val));
		});

		this.chart_def_daily = ko.observable({
			json: [],
			axis: {
				x: {
					type: 'category',
					tick: {
						rotate: -20,
						multiline: false
					},
					height: 75
				}
			},
			legend: {
				show: true
			}
		});
		this.graph_data_daily = ko.observableArray([]); // Reuse this to minimize number of DB calls
		this.graph_data_daily_duration = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'total_duration');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				xSort: true,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_daily_distance = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'total_distance');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_daily_trip_count = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'trip_count');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
	}

	async fetchInfo() {
		if (!this.client_id())
			return;

		let url = Grape.fetches.buildURL(
			'/api/record',
			{
				table: 'v_client',
				schema: 'mcab',
				filter: [{ field: 'client_id', oper: '=', value: this.client_id() }]
			},
			window.location);

		let response = await fetch(url);
		let json = await response.json();
		// TODO check
		let record = json.records[0];
		this.data(record);

		this.graph_data_daily(await GraphData.get_client_graph_data(this.client_id(), this.graph_filter_data(), this.graph_grouping()));
	}

	onVehicleRow_click(vehicle) {
		Grape.navigate(`/vehicle/${vehicle.vehicle_id}`);
	}

	onTripRow_click(trip) {
		Grape.navigate(`/trip/${trip.trip_id}`);
	}
}

class ClientPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new ClientVM(this);
		this.viewModel.client_id(bindings.client_id);
	}
	init() {
		document.title = 'Client';
	}
}

export default {
	route: '[/]client/:client_id',
	page_class: ClientPage,
	template: template
};
