
import template from './template.html';

class VehiclesVM {
	constructor(page) {
		this.page = page;
	}

	onRow_click(p) {
		console.log('row', p);
		Grape.navigate(`/vehicle/${p.vehicle_id}`);
	}
}

class VehiclesPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new VehiclesVM(this);
	}

	init() {
		document.title = 'Vehicles';
	}
}

export default {
	route: '[/]vehicles',
	page_class: VehiclesPage,
	template: template
};
