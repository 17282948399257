
import template from './template.html';

class ClientsVM {
	constructor(page) {
		this.page = page;
	}

	onRow_click(row) {
		Grape.navigate(`/client/${row.client_id}`);
	}
}

class ClientsPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new ClientsVM(this);
	}
	init() {
		document.title = 'Clients';
	}
}

export default {
	route: '[/]clients',
	page_class: ClientsPage,
	template: template
};
