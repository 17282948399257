
import template from './sqlquery.html';

class SQLQueryPageVM
{
	constructor()
	{
	}

	submit(formElement)
	{
		console.log('form=',formElement);
	}
}

class SQLQueryPagePage
{
	constructor(bindings, params, options, element)
	{
		this.viewModel = new SQLQueryPageVM(this);
	}

	init()
	{

	}
}

export default {
	route: '[/]sqlquery',
	page_class: SQLQueryPagePage,
	template: template
}
