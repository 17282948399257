
import template from './workflows.html';

class WorkflowsPageVM
{
	constructor()
	{
	}

}

class WorkflowsPagePage
{
	constructor(bindings, params, options, element)
	{
		this.viewModel = new WorkflowsPageVM(this);
	}

}

export default {
	route: '[/]workflows',
	page_class: WorkflowsPagePage,
	template: template
}
