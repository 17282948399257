
import Clients from './clients/index.js';
import Client from './client/index.js';
import Dashboards from './dashboards/dashboards.js';
import Footer from './footer/footer.js';
import Overview from './overview/index.js';
import Files from './files/files.js';
import Messages from './messages/messages.js';
import Graphs from './graphs/graphs.js';
import Workflows from './workflows/workflows.js';
import SQLQuery from './sqlquery/sqlquery.js';
import Sidebar from './sidebar/sidebar.js';
import Trip from './trip/index.js';
import Trips from './trips/index.js';
import Vehicle from './vehicle/index.js';
import Vehicles from './vehicles/index.js';

export default [
	Client,
	Clients,
	Dashboards,
	Footer,
	Files,
	Graphs,
	Overview,
	Messages,
	Sidebar,
	SQLQuery,
	Trips,
	Trip,
	Vehicles,
	Vehicle,
	Workflows
];
