
import template from './messages.html';

class MessagesPageVM
{
	constructor()
	{
	}

	submit(formElement)
	{
		console.log('form=',formElement);
	}
}

class MessagesPagePage
{
	constructor(bindings, params, options, element)
	{
		this.viewModel = new MessagesPageVM(this);
	}

}

export default {
	route: '[/]messages',
	page_class: MessagesPagePage,
	template: template
}
