
import template from './files.html';

class FilesPageVM
{
	constructor()
	{
	}

	submit(formElement)
	{
		console.log('form=',formElement);
	}
}

class FilesPagePage
{
	constructor(bindings, params, options, element)
	{
		this.viewModel = new FilesPageVM(this);
	}

}

export default {
	route: '[/]files',
	page_class: FilesPagePage,
	template: template
}
