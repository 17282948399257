
import MenuItem from './menu_item';
import template from './template.html';

class ComponentVM {
	constructor(params) {
		this.params = params;
		this.items = ko_helper.safe_observableArray(params.items, []);

		this.formatted_items = ko.pureComputed(() => {
			return this.items().map(itm => new MenuItem(itm));
		});
	}

	navigate(item) {
		if (item.route)
		{
			console.debug('Navigating to:', item);
			Grape.navigate(item.route);
		}
	}
}

export default {
	name: 'ps_sidebar',
	module_type: 'ko',
	viewModel: ComponentVM,
	template: template
};
