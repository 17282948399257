
import template from './dashboards.html';

class DashboardsVM {
	constructor() {
	}
}

class DashboardsPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new DashboardsVM(this);
	}
}

export default {
	route: '[/]dashboards',
	page_class: DashboardsPage,
	template: template
};
