
/* Application entry-point */

/* Import navbar */
import navbar from './lib/navbar.json';

/* Import css */
import 'c3/c3.min.css';
import './css/wireframe.css';
import './css/dashboard.css';

/* Import local code */
import caches from './caches/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';

/* Import plugins */
import DashboardPlugin from './lib/DashboardPlugin.js';

/* Define default routes */
const default_routes = {
	guest: '/grape-ui/login',
	all: '/overview'
};

/* Register page */
Grape.utils.register_caches(caches);
Grape.utils.register_components(components);
Grape.utils.register_dialogs(dialogs);
Grape.utils.register_pages(pages);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	/* Setup app */
	Grape.config.navbar = navbar;
	Grape.config.default_routes = default_routes;

	Grape.plugins.register(DashboardPlugin);

	/* Start app */
	Grape.init();
});
