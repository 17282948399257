export async function get_vehicle_graph_data(vehicle_id, period, grouping) {
	if (!vehicle_id)
		return [];

	let table = 'v_graph_vehicle_daily';
	switch (grouping)
	{
		case 'daily':
			table = 'v_graph_vehicle_daily';
			break;
		case 'weekly':
			table = 'v_graph_vehicle_weekly';
			break;
		case 'monthly':
			table = 'v_graph_vehicle_monthly';
			break;
		case 'yearly':
			table = 'v_graph_vehicle_yearly';
			break;
		default:
	}

	let filter = [{ field: 'vehicle_id', oper: '=', value: vehicle_id }];
	if (period && period.length > 0)
	{
		filter = filter.concat(period);
	}

	let url = Grape.fetches.buildURL(
		'/api/record',
		{
			table: table,
			schema: 'mcab',
			limit: 10000,
			filter: filter
		},
		window.location);

	let response = await fetch(url);
	let json = await response.json();

	return json.records || [];
}

export async function get_client_graph_data(client_id, period, grouping) {
	if (!client_id)
		return [];

	let table = 'v_graph_vehicle_daily';
	switch (grouping)
	{
		case 'daily':
			table = 'v_graph_vehicle_daily';
			break;
		case 'weekly':
			table = 'v_graph_vehicle_weekly';
			break;
		case 'monthly':
			table = 'v_graph_vehicle_monthly';
			break;
		case 'yearly':
			table = 'v_graph_vehicle_yearly';
			break;
		default:
	}

	let filter = [{ field: 'client_id', oper: '=', value: client_id }];
	if (period && period.length > 0)
	{
		filter = filter.concat(period);
	}

	let url = Grape.fetches.buildURL(
		'/api/record',
		{
			table: table,
			schema: 'mcab',
			limit: 10000,
			filter: filter
		},
		window.location);

	let response = await fetch(url);
	let json = await response.json() || [];

	return json.records || [];
}

export async function get_overview_graph_data(period, grouping) {
	let table = 'v_graph_vehicle_daily';
	switch (grouping)
	{
		case 'daily':
			table = 'v_graph_vehicle_daily';
			break;
		case 'weekly':
			table = 'v_graph_vehicle_weekly';
			break;
		case 'monthly':
			table = 'v_graph_vehicle_monthly';
			break;
		case 'yearly':
			table = 'v_graph_vehicle_yearly';
			break;
		default:
	}

	let filter = [];
	if (period && period.length > 0)
	{
		filter = filter.concat(period);
	}

	let url = Grape.fetches.buildURL(
		'/api/record',
		{
			table: table,
			schema: 'mcab',
			limit: 10000,
			filter: filter
		},
		window.location);

	let response = await fetch(url);
	let json = await response.json() || [];

	return json.records || [];
}

export function group_by_registration(data, field) {
	let res = {};

	data.forEach(entry => {
		if (!res[entry.period])
			res[entry.period] = { period: entry.period };

		let tgt = res[entry.period];
		tgt[entry.registration] = entry[field];
	});

	let series = Object.values(res);
	series.sort((a, b) => { return new Date(a.period) - new Date(b.period); });
	return series;
}

export function group_by_client(data, field) {
	let res = {};

	data.forEach(entry => {
		if (!res[entry.period])
			res[entry.period] = { period: entry.period };

		let tgt = res[entry.period];
		tgt[entry.client_name] = entry[field];
	});

	let series = Object.values(res);
	series.sort((a, b) => { return new Date(a.period) - new Date(b.period); });
	return series;
}

export function get_grouped_keys(data) {
	let keys = [];
	data.forEach(p => {
		Object.keys(p).forEach(key => {
			if (key === 'period') return;
			if (keys.indexOf(key) === -1)
				keys.push(key);
		});
	});

	return keys;
}
