import * as GraphData from '../../lib/GraphData.js';
import template from './template.html';

class VehicleVM {
	constructor(page) {
		this.page = page;

		this.data = ko.observable(null);
		this.vehicle_id = ko.observable();

		this.vehicle_id.subscribe((id) => {
			this.fetchInfo();
		});

		this.graph_filter_options = ko.observable([
			'today', 'this week', 'this month', 'last month', 'this year', 'all time'
		]);
		this.graph_filter = ko.observable('last month');
		this.graph_filter_data = ko.pureComputed(() => {
			let filter = this.graph_filter();
			let from_value = moment();

			let from = { field: 'period', oper: '>=', value: '' };
			if (filter === 'today')
			{
				from.value = from_value.startOf('day').format('YYYY-MM-DD');
			}
			else if (filter === 'this week')
			{
				from.value = from_value.startOf('week').format('YYYY-MM-DD');
			}
			else if (filter === 'this month')
			{
				from.value = from_value.startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'last month')
			{
				from.value = from_value.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'this year')
			{
				from.value = from_value.startOf('year').format('YYYY-MM-DD');
			}
			else { return []; }

			return [from];
		});
		this.graph_filter_data.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_vehicle_graph_data(this.vehicle_id(), val, this.graph_grouping()));
		});

		this.graph_grouping = ko.observable('daily');
		this.graph_grouping.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_vehicle_graph_data(this.vehicle_id(), this.graph_filter_data(), val));
		});

		this.chart_def_daily = ko.observable({
			json: [],
			axis: {
				x: {
					type: 'category',
					tick: {
						rotate: -20,
						multiline: false
					},
					height: 75
				}
			},
			legend: {
				show: false
			}
		});
		this.graph_data_daily = ko.observableArray([]); // Reuse this to minimize number of DB calls
		this.graph_data_daily_duration = ko.pureComputed(() => {
			return {
				type: 'bar',
				colors: { total_duration: '#d62728' },
				json: this.graph_data_daily(),
				keys: {
					x: 'period',
					value: ['total_duration']
				}
			};
		});
		this.graph_data_daily_distance = ko.pureComputed(() => {
			return {
				type: 'bar',
				colors: { total_distance: '#1f77b4' },
				json: this.graph_data_daily(),
				keys: {
					x: 'period',
					value: ['total_distance']
				}
			};
		});
		this.graph_data_daily_trip_count = ko.pureComputed(() => {
			return {
				type: 'bar',
				colors: { trip_count: '#ff7f0e' },
				json: this.graph_data_daily(),
				keys: {
					x: 'period',
					value: ['trip_count']
				}
			};
		});
	}

	async fetchInfo() {
		if (!this.vehicle_id())
			return;

		let url = Grape.fetches.buildURL(
			'/api/record',
			{
				table: 'v_vehicle',
				schema: 'mcab',
				filter: [{ field: 'vehicle_id', oper: '=', value: this.vehicle_id() }]
			},
			window.location);

		let response = await fetch(url);
		let json = await response.json();
		// TODO check
		let record = json.records[0];
		this.data(record);

		this.graph_data_daily(await GraphData.get_vehicle_graph_data(this.vehicle_id(), this.graph_filter_data(), this.graph_grouping()));
	}

	onTripRow_click(trip) {
		Grape.navigate(`/trip/${trip.trip_id}`);
	}
}

class VehiclePage {
	constructor(bindings, params, options, element) {
		this.viewModel = new VehicleVM(this);
		this.viewModel.vehicle_id(bindings.vehicle_id);
	}
	init() {
		document.title = 'Vehicle';
	}
}

export default {
	route: '[/]vehicle/:vehicle_id',
	page_class: VehiclePage,
	template: template
};
