import template from './template.html';
import * as C3 from 'c3';

const c3 = C3.default;

class ComponentVM {
	constructor(params) {
		this.id = `graph_${Grape.utils.generate_random_string(15)}`;
		this.title = ko_helper.safe_observable(params.title);
		this.name = ko_helper.safe_observable(params.name);
		this.error = ko.observable();

		this.chart_def = ko_helper.safe_observable(params.chart_def, {});
		this.data = ko_helper.safe_observable(params.data);
		this.data.subscribe((new_val) => {
			this.refresh_data(new_val);
		});

		this.hidden = ko.observable(false);
		this.toggle_text = ko.observable('Hide All');
	}

	get svg_selector() { return `#${this.id} > svg`; }

	after_render() {
		this.draw_chart(this.chart_def());
	}

	dispose() {
		if (this.chart)
		{
			this.chart.destroy();
			super.dispose();
		}
	}

	draw_chart(config) {
		config.bindto = '#' + this.id;
		config.data = config.data ?? {};
		config.data.json = config.data.json ?? [];

		this.chart = c3.generate(config);
	}

	refresh_data(data) {
		data = data ?? {};
		data.json = data.json ?? [];
		data.unload = true;

		if (this.chart)
		{
			this.chart.load(data);
		}
	}

	toggle_all(model, e) {
		if (this.chart)
		{
			if (this.hidden())
			{
				this.chart.show();
				this.hidden(false);
				this.toggle_text('Hide all');
			}
			else
			{
				this.chart.hide();
				this.hidden(true);
				this.toggle_text('Show all');
			}
		}
	}
}

export default {
	name: 'graph-panel',
	module_type: 'ko',
	viewModel: ComponentVM,
	template: template
};
