
import template from './footer.html';

class FooterVM {
	constructor() {
		this.websocket_status_icon = ko.observable('');
		this.websocket_status_text = ko.observable('Connecting...');
	}
}

class FooterPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new FooterVM(this);
	}
}

export default {
	route: '[/]footer',
	page_class: FooterPage,
	template: template
};
