
import template from './template.html';

class TripVM {
	constructor(page) {
		this.page = page;

		this.data = ko.observable(null);
		this.trip_id = ko.observable();

		this.trip_id.subscribe((id) => {
			this.fetchInfo();
		});
	}

	async fetchInfo() {
		if (!this.trip_id())
			return;

		let url = Grape.fetches.buildURL(
			'/api/record',
			{
				table: 'v_trip',
				schema: 'mcab',
				filter: [{ field: 'trip_id', oper: '=', value: this.trip_id() }]
			},
			window.location);

		let response = await fetch(url);
		let json = await response.json();
		// TODO check
		let record = json.records[0];
		this.data(record);

		try
		{
			// LINESTRING (-33.9422 18.8291, -33.9442 18.8331, -33.9442 18.8335, -33.9421 18.8432, -33.9422 18.8457, -33.9375 18.851, -33.9375 18.8513, -33.9407 18.852, -33.9442 18.8526, -33.9443 18.8526, -33.9447 18.8525, -33.9449 18.8517, -33.9448 18.8513, -33.9447 18.8513, -33.9443 18.8515, -33.944 18.8513, -33.944 18.851)

			let lg = record.line_geometry;
			let str = lg.substring(lg.indexOf('(') + 1);
			let ar = str.split(',');
			let poly = [];
			for (let a of ar)
			{
				a = a.trim();
				let arr = a.split(' ');
				poly.push([
					parseFloat(arr[0]), parseFloat(arr[1])
				]);
			}

			console.log('poly=', poly);
			const map = this.createMap(poly[0]);
			const polygon = L.polygon(poly).addTo(map).bindPopup(`Trip #${this.data().trip_id}`);
		} catch (err)
		{
			console.error(err);
		}
	}

	createMap(iview) {
		const map = L.map('map').setView(iview, 13);

		const tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 19
		}).addTo(map);
		return map;
	}
}

class TripPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new TripVM(this);
		this.viewModel.trip_id(bindings.trip_id);
	}

	init() {
		document.title = 'Trip';
	}
}

export default {
	route: '[/]trip/:trip_id',
	page_class: TripPage,
	template: template
};
