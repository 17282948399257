
import template from './template.html';

/*
 * Params:
 * - value text: The currently selected value. Will always be converted to lowercase
 * - options text[]: The list of options to display. Default [daily, weekly, monthly, yearly]
 * - default_option text: The default option to select when no other is selected. Default options_list[0]
 * - format_values bool: Indicates whether the component should apply default display formatting to the option text. Default true
 */
class ComponentVM {
	constructor(params) {
		this.value = ko_helper.safe_observable(params.value);
		this.default_value = ko_helper.safe_observable(params.default_value, 'daily');
		this.option_list = ko_helper.safe_observable(params.options, [
			'daily',
			'weekly',
			'monthly',
			'yearly'
		]);
		this.options = ko.pureComputed(() => {
			return this.option_list().map(o => {
				return {
					value: (o ?? '').toLowerCase(),
					display: o
				};
			});
		});
		this.format_values = ko_helper.safe_observable(params.format_values, true);

		this.validate_value();
	}

	validate_value() {
		let options = this.options().map(o => { return o.value; });
		let val = (this.value() ?? '').toLowerCase();
		let def = (this.default_value() ?? '').toLowerCase();

		if (options.indexOf(val) >= 0)
		{
			if (this.value() !== val) this.value(val);
		}
		else
		{
			if (options.indexOf(def) >= 0) { this.value(def); }
			else if (options.length > 0) { this.value(options[0]); }
			else { this.value(null); }
		}
	}

	toggle_value(btn_type) {
		if (btn_type && this.value() !== btn_type)
			this.value(btn_type);
	}
}

export default {
	name: 'date_group_selector',
	module_type: 'ko',
	viewModel: ComponentVM,
	template: template
};
