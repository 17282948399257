
import template from './template.html';

class TripsVM {
	constructor(page) {
		this.page = page;
	}

	onRow_click(trip) {
		Grape.navigate(`/trip/${trip.trip_id}`);
	}
}

class TripsPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new TripsVM(this);
	}

	init() {
		document.title = 'Trips';
	}
}

export default {
	route: '[/]trips',
	page_class: TripsPage,
	template: template
};
