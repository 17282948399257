import * as GraphData from '../../lib/GraphData.js';
import template from './template.html';

class OverviewVM {
	constructor(page) {
		this.page = page;

		this.graph_filter_options = ko.observable([
			'today', 'this week', 'this month', 'last month', 'this year', 'all time'
		]);
		this.graph_filter = ko.observable('last month');
		this.graph_filter_data = ko.pureComputed(() => {
			let filter = this.graph_filter();
			let from_value = moment();

			let from = { field: 'period', oper: '>=', value: '' };
			if (filter === 'today')
			{
				from.value = from_value.startOf('day').format('YYYY-MM-DD');
			}
			else if (filter === 'this week')
			{
				from.value = from_value.startOf('week').format('YYYY-MM-DD');
			}
			else if (filter === 'this month')
			{
				from.value = from_value.startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'last month')
			{
				from.value = from_value.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
			}
			else if (filter === 'this year')
			{
				from.value = from_value.startOf('year').format('YYYY-MM-DD');
			}
			else { return []; }

			return [from];
		});
		this.graph_filter_data.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_overview_graph_data(val, this.graph_grouping()));
		});

		this.graph_grouping = ko.observable('weekly');
		this.graph_grouping.subscribe(async val => {
			this.graph_data_daily(await GraphData.get_overview_graph_data(this.graph_filter_data(), val));
		});

		this.chart_def_daily = ko.observable({
			json: [],
			axis: {
				x: {
					type: 'category',
					tick: {
						rotate: -20,
						multiline: false
					},
					height: 75
				}
			},
			legend: {
				show: true
			}
		});
		this.chart_def_cumulative = ko.observable({
			json: [],
			axis: {
				x: {
					type: 'category',
					tick: {
						rotate: -20,
						multiline: false
					},
					height: 75
				}
			},
			legend: {
				show: true
			}
		});
		this.graph_data_daily = ko.observableArray([]); // Reuse this to minimize number of DB calls
		this.graph_data_ev_duration = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'total_duration');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_client_duration = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_client(data, 'total_duration');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_ev_distance = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'total_distance');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_client_distance = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_client(data, 'total_distance');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_ev_trip_count = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_registration(data, 'trip_count');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});
		this.graph_data_client_trip_count = ko.pureComputed(() => {
			let data = this.graph_data_daily();
			let graph_grouped = GraphData.group_by_client(data, 'trip_count');
			let keys = GraphData.get_grouped_keys(graph_grouped);

			return {
				type: 'bar',
				json: graph_grouped,
				keys: {
					x: 'period',
					value: keys
				}
			};
		});

		let x = new Promise(async (resolve, reject) => {
			this.graph_data_daily(await GraphData.get_overview_graph_data(this.graph_filter_data(), this.graph_grouping()));
			resolve();
		});
	}

	onRow_click() {
	}
}

class OverviewPage {
	constructor(bindings, params, options, element) {
		this.viewModel = new OverviewVM(this);
	}

	init() {
		document.title = 'Overview';
	}
}

export default {
	route: '[/]overview',
	page_class: OverviewPage,
	template: template
};
