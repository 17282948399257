

class DashboardPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
	}

	beforeNavbarRender()
	{
	}

	afterNavbarRender()
	{
		let element = document.querySelector('#menu .navbar-header');
		console.log('element=',element);
		if (!element)
			return;
		element.innerHTML = '<img src="logo_small.png" style="margin: 5px; height: 30px;" />';

	}

	onLoad()
	{
		console.log('onLoad');
		console.log('this.Grape=', this.Grape);

		let element;
		element = document.querySelector('div#sidebar');
		Grape.pages.load_page(
			Grape.pages.find_page_by_id('[/]sidebar'),
			element,
			{},
			{},
			{},
			() => { }
		);

		element = document.querySelector('div#footer');
		Grape.pages.load_page(
			Grape.pages.find_page_by_id('[/]footer'),
			element,
			{},
			{},
			{},
			() => { }
		);

	}

	afterInit()
	{
	}
}

export default DashboardPlugin;

