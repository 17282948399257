export default class MenuItem {
	constructor(params) {
		this.params = params;

		this.title = ko_helper.safe_observable(params.title, '');
		this.icon = ko_helper.safe_observable(params.icon, '');

		this.items = ko_helper.safe_observable(params.items, []);
		this.formatted_items = ko.pureComputed(() => {
			return this.items().map(itm => new MenuItem(itm));
		});
	}
}
